var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"},style:({
  backgroundColor: _vm.module.style.bgcolor
})},[_c('div',{staticClass:"top-bar"},[_c('div',{staticClass:"top-bar__inner"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),(this.loginStatus)?_c('span',[_vm._m(4)]):_vm._e()])]),_c('div',{staticClass:"head_nav"},[_c('div',{staticClass:"head_centent"},[_c('div',{staticClass:"header_1",style:({
        color: _vm.module.style.color
      })},[_vm._m(5),_c('div',{staticClass:"fa",staticStyle:{"width":"60%"}},[_c('span',{staticStyle:{"cursor":"pointer"}},[_vm._v(" 首页 ")]),_vm._l((_vm.module.list),function(item,index){return _c('span',{key:index,staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"bar-item",attrs:{"href":"/"}},[_c('i',{staticClass:"el-icon-s-home icon"}),_c('span',{staticClass:"text"},[_vm._v("商城首页")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bar-item ml"},[_c('i',{staticClass:"el-icon-tickets icon"}),_c('span',{staticClass:"text"},[_vm._v("我的订单")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('a',{staticClass:"bar-item"},[_c('i',{staticClass:"el-icon-user icon"}),_c('span',{staticClass:"text"},[_vm._v("个人中心")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"cursor":"pointer"}},[_c('a',{staticClass:"bar-item"},[_c('i',{staticClass:"el-icon-headset icon"}),_c('span',{staticClass:"text"},[_vm._v("联系客服")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"bar-item",staticStyle:{"cursor":"pointer"}},[_c('i',{staticClass:"el-icon-switch-button icon"}),_c('span',{staticClass:"text"},[_vm._v("退出")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"header_logo",staticStyle:{"cursor":"pointer"}},[_c('img',{attrs:{"src":"/logo.png"}})])
}]

export { render, staticRenderFns }