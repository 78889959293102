<template>
  <div>
    <!-- <van-nav-bar title="" left-text="返回" left-arrow @click-left="toBack()" /> -->
    <van-sticky>
      <div class="product-list-header">
        <div class="title">
          <van-search @click="toSearch" show-action v-model="queryParam.searchKeyword" background="#E9DFDE"
            shape="round" placeholder="请输入搜索关键词">
            <template #left>
              <div @click="toBack()">
                <van-icon name="arrow-left" size="22" style="margin-right: 5px; margin-top: 2px" />
              </div>
            </template>

            <template #action>
              <div @click="dialogMenuVisible = true">
                <van-icon name="apps-o" size="22" style="margin-left: 5px; margin-top: 2px" />
              </div>
            </template>
          </van-search>
        </div>
      </div>
    </van-sticky>

    <div class="flex-shrink-0 main has-footer">
      <div>
        <!-- <van-tabs class="menu-tabs" animated :ellipsis="false">
          <van-tab title="全部"> -->
        <div class="dro-menu">
          <van-dropdown-menu @dropdown-menu-box-shadow="0">
            <van-dropdown-item v-model="value1" :options="option1" @change="handleQuery1()" />
            <van-dropdown-item v-model="value2" :options="option2" @change="handleQuery2()" />
          </van-dropdown-menu>
        </div>

        <van-list v-model="loadingState" :finished="finished" finished-text="没有更多了" @load="loadMore">
          <div class="main-container">
            <div>
              <div class="kind-show goods-list">
                <div>
                  <div>
                    <div>
                      <!---->
                      <div class="goods-content">
                        <div class="goods-item" :class="{ overlay: v.inventoryState == '3' }"
                          v-for="(v, k, i) in goodsList" :key="i" :title="v.name" @click="toProductDetails(v)">
                          <div class="goods-img">

                            
                            <el-image :src="v.picUrl">
                              <template slot="error">
                                <el-image :src="$defaultImage" style="width: 100%;"></el-image>
                              </template>
                            </el-image>

                            <!-- <el-image
                              style="
                                background-position: 0% 0%;
                                background-size: 100% 100%;
                                border-radius: 5px;
                              "
                              :src="v.picUrl"
                            ></el-image> -->
                          </div>

                          <div class="product_centent">
                            <span class="name">{{ v.productName }}</span>
                            <!-- <div>
                              <span>
                                <van-tag
                                  type="primary"
                                  :color="v.productTypeColor"
                                >
                                  {{ v.productTypeText }}
                                </van-tag>
                              </span>
                            </div> -->

                            <div class="fun" style="margin-top: 20px">
                              <!-- 价格 -->
                              <span class="money">
                                <span>
                                  <span v-html="$decimalPrice(v.price)"> </span>
                                </span>
                                <span class="default-money" v-if="v.parValue">
                                  <span> ￥{{ v.parValue }}</span>
                                </span>
                              </span>

                              <!-- 按钮 -->
                              <span style="
                                  border: 1px solid #e1c1c7;
                                  background-color: #fef4f3;
                                  display: inline-block;
                                  height: 26px;
                                  padding: 0 10px;
                                  line-height: 24px;
                                  font-size: 12px;
                                  color: #d57081;
                                  border-radius: 20px;
                                ">
                                立即购买
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>

    <!-- 搜索辅助菜单 -->
    <van-popup v-model="dialogMenuVisible" position="bottom" round :style="{ height: '30%' }">
      <div style="margin-top: 10px">
        <van-grid :column-num="4" :border="false">
          <van-grid-item icon="home-o" text="商城首页" to="/mindex" />

          <van-grid-item icon="apps-o" text="商品分类" to="/cate" />

          <van-grid-item icon="user-o" text="我的账户" to="/my" />

          <van-grid-item icon="orders-o" text="我的订单" to="/order" />

          <van-grid-item icon="bullhorn-o" text="商城公告" to="/sysNotice" />

          <van-grid-item icon="service-o" text="联系客服" to="/contact" />
        </van-grid>

        <div style="padding: 15px">
          <van-button type="default" size="large" round @click="dialogMenuVisible = false">关闭</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
// import "@/assets/css/style.css";
import { queryProductList } from "@/api/index";

export default {
  name: "mProductList",
  data() {
    return {
      dialogMenuVisible: false,

      showOverlay: true,
      value1: null,
      value2: null,
      option1: [
        { text: "全部商品", value: null },
        { text: "直冲商品", value: 1 },
        { text: "卡密商品", value: 3 },
      ],
      option2: [
        { text: "默认排序", value: null },
        { text: "销量排序", value: 4 },
        { text: "价格排序", value: 5 },
      ],
      goodsLabelList: [],
      goodsList: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        categoryId: undefined,
        searchKeyword: undefined,
        queryCondition: null,
      },
      queryGoodsRelationSpecsParam: {
        goodsId: undefined,
      },
      queryParamName: {
        specsName: undefined,
      },
      goodsName: "",
      goodsStatus: false,
      pageNum: 1,
      pageSize: 10,
      loadingState: false,
      finished: false,
      isFetching: false, // 是否有正在进行中的请求
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      productList: (state) => state.index.productList,
    }),
  },
  activated() {
    this.dialogMenuVisible = false;

    if (!this.$route.meta.isRefresh) {
      // 如果isBack是false，表明需要获取新数据，否则就不再请求，直接使用缓存的数据
      // 如果isFirstEnter是true，表明是第一次进入此页面或用户刷新了页面，需获取新数据
      this.goodsList = [];
      this.queryParam.pageNum = 1;
      this.finished = false;
      this.queryParam.categoryId = this.$route.query.categoryId;
      this.queryParam.searchKeyword = this.$route.query.goodsName;
      this.getProductList();
    }
    // 恢复成默认的false，避免isBack一直是true，导致下次无法获取数据
    this.$route.meta.isRefresh = false;
  },
  created() {
    this.queryParam.categoryId = this.$route.query.categoryId;
    this.queryParam.searchKeyword = this.$route.query.goodsName;
  },
  methods: {
    //查询商品列表
    getProductList() {
      console.log("加载更多1========");

      this.isFetching = true;
      new Promise((resolve, rejust) => {
        queryProductList(this.queryParam)
          .then((res) => {
            //主站
            this.goodsList = this.goodsList.concat(res.result.productList);

            this.total = res.result.total;
            if (res.result.productList.length == 0) {
              this.finished = true;
            }
            this.loadingState = false;
            this.isFetching = false;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 加载更多
     */
    loadMore() {
      console.log("加载更多========");
      if (this.isFetching) {
        // 如果有正在进行中的请求，则不继续发起新的请求
        return;
      }
      this.queryParam.pageNum = this.queryParam.pageNum + 1;
      this.loadingState = true;
      this.getProductList();
    },

    /**
     * 查询商品1
     */
    handleQuery1() {
      this.queryParam.queryCondition = this.value1;
      this.goodsList = [];
      this.queryParam.pageNum = 1;

      this.finished = false;

      this.loadingState = true;
      this.getProductList();
    },

    /**
     * 查询商品2
     */
    handleQuery2() {
      this.queryParam.queryCondition = this.value2;
      this.goodsList = [];
      this.queryParam.pageNum = 1;

      this.finished = false;

      this.loadingState = true;
      this.getProductList();
    },

    //跳转商品详情界面
    toProductDetails(v) {

      this.$router.push(`/product_detail/${v.id}`);
    },

    // 查询商品列表
    toBack() {
      // this.$router.push("/search");
      this.$router.go(-1);
    },

    // 跳转到搜索页面
    toSearch() {
      this.$router.push("/search");
    },
  },
};
</script>
<style scoped>
.goods-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.625rem;
}

.goods-content .goods-item {
  width: 100%;
  /* height: 6.25rem; */
  background: #ffffff;
  box-shadow: 0 0.125rem 0.1875rem #f5f7fa;
  border-radius: 0.3125rem;
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  gap: 0.625rem;
  padding: 0.625rem;
}

.goods-content .goods-item .goods-img {
  width: 6rem;
  height: 6rem;
  border-radius: 5px;
  overflow: hidden;
}

.goods-content .goods-item .product_centent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  gap: 10px;
}

.goods-content .goods-item .product_centent .name {
  color: #191e2e;
  font-weight: 400;
  /* font-size: 0.8125rem; */
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 14.375rem; */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 42px;
}

.goods-content .goods-item .product_centent .fun {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.goods-content .goods-item .product_centent .fun .money {
  font-weight: 500;
  color: #f84d43;
  font-size: 12px;
}

.goods-content .goods-item .product_centent .fun .money .default-money {
  font-weight: 400;
  color: #d2d5dd;
  font-size: 0.6875rem;
  text-decoration: line-through;
  margin-left: 0.53125rem;
}

.goods-content .goods-item .product_centent .fun .buy-btn {
  width: 4.0625rem;
  height: 1.5625rem;
  background: linear-gradient(90deg, #fe8a56 0%, #ff504b 100%);
  border-radius: 0.78125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
}

/* toubu */
.product-list-header {
  /* padding: 5px 0px 5px 0px; */
  /* width: 23.4375rem; */
  /* height: 4.5rem; */
  background: #f5e6df;
  border-radius: 0 0 0.46875rem 0.46875rem;
  /* padding: 1.25rem 0.875rem 0; */
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  display: flex;
  position: relative;
  box-sizing: border-box !important;
}

.product-list-header .title {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* gap: 1.5625rem; */
  position: relative;
}

.product-list-header .title .one-name {
  font-weight: 550;
  font-size: 0.9375rem;
  color: #1e2331;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
}

.product-list-header .title .one-name .un-text {
  white-space: nowrap;
}

.u-icon--right[data-v-694ec2d2] {
  flex-direction: row;
  align-items: center;
}

.u-icon[data-v-694ec2d2] {
  display: inline-flex;
}

.u-icon__icon[data-v-694ec2d2] {
  position: relative;
}

.u-iconfont[data-v-694ec2d2] {
  display: flex;
  font: 14px/1 uicon-iconfont;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.product-list-header .title .search-box {
  width: 15rem;
  height: 2.125rem;
  background: #f0f3f7;
  border-radius: 1.0625rem;
  display: flex;
  align-items: center;
  padding: 0 0.84375rem;
  color: #9b9fa8;
}

.u-icon--right[data-v-694ec2d2] {
  flex-direction: row;
  align-items: center;
}

.product-list-header .title .search-box uni-input {
  padding: 0 0.40625rem;
}

uni-input {
  display: block;
  font-size: 16px;
  line-height: 1.4em;
  height: 1.4em;
  min-height: 1.4em;
  overflow: hidden;
}

.product-list-header .two-box {
  height: 4.0625rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1.28125rem;
  width: 100%;
  position: relative;
  margin-top: 1.03125rem;
  padding: 1px 0;
}

.product-list-header .two-box .active {
  color: #fd4d20;
}

.product-list-header .two-box .item {
  width: 3.28125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.3125rem;
  color: #9b9fa8;
}

.product-list-header .two-box .active .img-box {
  box-shadow: 0 0 0 1px #fd4d20;
}

.product-list-header .two-box .item .img-box {
  background: #ffffff;
  box-shadow: 0 0 0 1px #e9edf2;
  border-radius: 50%;
  width: 2.875rem;
  height: 2.875rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-list-header .two-box .item .img-box .img {
  width: 2.875rem;
  height: 2.875rem;
}

.product-list-header .two-box .item .un-text {
  max-width: 3.59375rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.kind-box {
  display: flex;
  height: 2.5rem;
  align-items: center;
  width: 100%;
  padding: 0 0.625rem;
  gap: 0.625rem;
}

.kind-box .active {
  color: #fff;
  background: linear-gradient(90deg, #fe8a56 0%, #ff504b 100%);
}

.kind-box .kind-item {
  padding: 0.3125rem 0.625rem;
  color: #fff;
  border-radius: 0.625rem;
  background-color: #9b9fa8;
}

/* 标签栏样式 */

.tabInvoice {
  background: #333;
  border-radius: 60rpx;
  height: 75rpx;
}

.tabSon {
  border-radius: 60rpx;
  /* color: #fff !important; */
  line-height: 75rpx !important;
}

.activeTab {
  /* background: #ccc; */
  line-height: 75rpx !important;
}

.menu-tabs .van-tab--active {
  color: #ffffff;
  font-size: 15px;
  background-color: #3e8eff;
  border-radius: 10px;
  /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
}

.menu-tabs .van-tabs__line {
  background-color: #3e8eff;
  width: 40px;
  height: 0px;
  border-radius: 40px;
}

.goods-item.overlay {
  position: relative;
}

.goods-item.overlay::after {
  content: "已售空";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 128, 128, 0.3);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-family: "Arial", sans-serif;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  z-index: 9999;
}

.dro-menu .van-dropdown-menu__bar {
  box-shadow: none;
}

/deep/.van-dropdown-menu__bar {
  box-shadow: unset !important;
}
</style>
