<template>
  <div>
    <div class="h-footer-fn">
      <div class="h-footer-advantage">
        <ul>
          <li>
            <i class="footer-icon"><svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" style="
                    vertical-align: middle;
                    fill: currentcolor;
                    overflow: hidden;
                  ">
                <path
                  d="M270.88 335.87l192-242.53a62.536 62.536 0 0 1 49.01-23.71c19.1 0 37.16 8.73 49.02 23.71l192 242.53 183.75-78.75a62.523 62.523 0 0 1 64.18 9.06 62.522 62.522 0 0 1 21.65 61.1L915.18 844.29c-12.99 62.59-68.14 107.46-132.07 107.46H240.67c-63.93 0.01-119.08-44.86-132.07-107.46L1.31 327.28c-4.73-22.81 3.61-46.36 21.65-61.1a62.48 62.48 0 0 1 64.18-9.06l183.75 78.75h-0.01z m241.01-180.73L324.3 392.08c-17.52 22.13-47.69 29.78-73.64 18.66L82.05 338.47l101.74 490.21c5.59 26.95 29.34 46.29 56.87 46.29h542.46c27.53 0 51.28-19.33 56.87-46.29l101.74-490.21-168.62 72.27c-25.95 11.12-56.12 3.47-73.64-18.66L511.89 155.14z m0 0">
                </path>
              </svg></i><strong>优质服务</strong>
            <p>用户优选，服务品质有保障</p>
          </li>
          <li>
            <i class="footer-icon"><svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" style="
                    vertical-align: middle;
                    fill: currentcolor;
                    overflow: hidden;
                  ">
                <path
                  d="M914.048 209.408L575.744 17.024a130.624 130.624 0 0 0-128.512 0L108.928 209.408a126.528 126.528 0 0 0-64 109.568v384.832a126.656 126.656 0 0 0 64 109.568l338.304 192.448a130.624 130.624 0 0 0 128.512 0L914.048 813.44a126.656 126.656 0 0 0 64-109.632V319.04a126.528 126.528 0 0 0-64-109.632z m-2.624 494.4a50.304 50.304 0 0 1-25.6 43.584L536.896 950.208a52.032 52.032 0 0 1-51.2 0L136.768 747.392a50.304 50.304 0 0 1-25.6-43.584V319.04a50.304 50.304 0 0 1 25.6-43.584L485.696 72.64a52.032 52.032 0 0 1 51.2 0l348.928 202.816a50.304 50.304 0 0 1 25.6 43.584z m0 0">
                </path>
                <path
                  d="M322.048 352.768c-18.304 0-28.032-10.624-29.376-32a29.824 29.824 0 0 1 29.376-32h375.04c16.896 1.472 26.688 12.096 29.312 32-2.624 21.312-12.416 32-29.312 32H558.4v112.896h134.4c16.96 1.408 26.688 12.8 29.312 34.048-2.624 21.248-12.352 32-29.312 32H558.4V670.08h148.416a31.232 31.232 0 0 1 31.232 29.824c0 21.312-9.088 32.64-27.328 34.112H312.192q-25.6-2.176-27.328-34.112a28.032 28.032 0 0 1 27.328-29.824h39.104V442.304a32 32 0 0 1 29.312-36.224 34.24 34.24 0 0 1 31.232 36.224V670.08h83.968V352.768z m0 0">
                </path>
              </svg></i><strong>官方授权</strong>
            <p>正版官方，购物安全无风险</p>
          </li>
          <li>
            <i class="footer-icon"><svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" style="
                    vertical-align: middle;
                    fill: currentcolor;
                    overflow: hidden;
                  ">
                <path
                  d="M512 0C229.248 0 0 229.248 0 512s229.248 512 512 512 512-229.248 512-512S794.752 0 512 0z m0 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333z">
                </path>
                <path
                  d="M689.450667 266.538667a42.666667 42.666667 0 0 1 7.253333 55.978666l-3.242667 4.266667L605.994667 426.666667H661.333333a42.666667 42.666667 0 0 1 4.992 85.034666L661.333333 512H554.666667v85.333333h85.333333a42.666667 42.666667 0 0 1 4.992 85.034667L640 682.666667h-85.333333v42.666666a42.666667 42.666667 0 0 1-85.034667 4.992L469.333333 725.333333v-42.666666H384a42.666667 42.666667 0 0 1-4.992-85.034667L384 597.333333h85.333333v-85.333333H362.666667a42.666667 42.666667 0 0 1-4.992-85.034667L362.666667 426.666667h55.296L330.538667 326.784a42.666667 42.666667 0 0 1 60.458666-60.032l3.797334 3.84L512 404.48l117.205333-133.973333a42.666667 42.666667 0 0 1 60.245334-4.010667z">
                </path>
              </svg></i><strong>低价保障</strong>
            <p>天天低价，畅选购物更便宜</p>
          </li>
          <li>
            <i class="footer-icon"><svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" style="
                    vertical-align: middle;
                    fill: currentcolor;
                    overflow: hidden;
                  ">
                <path
                  d="M121.904762 914.285714c0-21.942857 17.066667-39.009524 39.009524-41.447619l704.609524-7.314285c21.942857 0 39.009524 17.066667 41.447619 39.009523 0 21.942857-17.066667 39.009524-39.009524 41.447619l-704.609524 7.314286c-21.942857 0-41.447619-17.066667-41.447619-39.009524zM514.438095 802.133333c-158.47619 0-287.695238-129.219048-287.695238-287.695238S355.961905 224.304762 514.438095 224.304762 802.133333 353.52381 802.133333 512s-129.219048 290.133333-287.695238 290.133333z m0-497.371428c-114.590476 0-207.238095 92.647619-207.238095 207.238095s92.647619 207.238095 207.238095 207.238095 207.238095-92.647619 207.238095-207.238095-92.647619-207.238095-207.238095-207.238095zM936.228571 433.980952C916.72381 219.428571 736.304762 48.761905 514.438095 48.761905 297.447619 48.761905 117.028571 214.552381 92.647619 426.666667c-48.761905 4.87619-85.333333 46.32381-85.333333 95.085714v48.761905c0 53.638095 43.885714 95.085714 95.085714 95.085714s95.085714-43.885714 95.085714-95.085714v-48.761905c0-26.819048-9.752381-51.2-29.257143-68.266667 9.752381-180.419048 160.914286-324.266667 343.771429-324.266666 187.733333 0 338.895238 148.72381 343.771429 334.019047-17.066667 17.066667-26.819048 39.009524-26.819048 65.828572v48.761904c0 53.638095 43.885714 95.085714 95.085714 95.085715 53.638095 0 95.085714-43.885714 95.085715-95.085715v-48.761904c4.87619-48.761905-34.133333-90.209524-82.895239-95.085715zM119.466667 570.514286c0 9.752381-7.314286 17.066667-17.066667 17.066666s-17.066667-7.314286-17.066667-17.066666v-48.761905c0-9.752381 7.314286-17.066667 17.066667-17.066667s17.066667 7.314286 17.066667 17.066667v48.761905z m824.07619 7.314285c0 9.752381-7.314286 17.066667-17.066667 17.066667-9.752381 0-17.066667-7.314286-17.066666-17.066667v-48.761904c0-9.752381 7.314286-17.066667 17.066666-17.066667 9.752381 0 17.066667 7.314286 17.066667 17.066667v48.761904z">
                </path>
              </svg></i><strong>售后无忧</strong>
            <p>全天客服，极速响应贴心服务</p>
          </li>
        </ul>
      </div>


      <!-- 底部内容 -->
      <div class="h-footer-about">
        <div>
          <div class="h-f-col" v-for="(item, index) in noticeList" :key="index">
            <div class="h-title">{{ item.cateName }}</div>

            <ul v-for="(content, index) in item.contentList" :key="index">
              <template>
                <li @click="toNoticeDetails(content)" style="cursor: pointer;">
                  <a>{{ content.contentTitle }}</a>
                </li>
              </template>
            </ul>
          </div>

          <template v-if="module.list.length > 0">
            <div class="h-f-col" style="float: right;">
              <div class="h-title">关注我们</div>
              <ul class="h-footer-qrcode">
                <li  v-for="(item, index) in module.list" :key="index">
                  <img :src="item.icon" />
                  <div>{{ item.title }}</div>
                </li>
              </ul>
            </div>
          </template>

        </div>
      </div>
    </div>
    <div class="h-footer-group">
      <div class="h-footer">
        <div class="h-copyright-group" style="text-align: center">
          <span>换换云商城</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["module"],
  data() {
    return {
      noticeList: [
        {
          cateName: "产品服务",
          contentList: [
            {
              contentTitle: "权益商城",
            }, {
              contentTitle: "权益商城",
            }, {
              contentTitle: "权益商城",
            }, {
              contentTitle: "权益商城",
            }
          ],
        }, {
          cateName: "产品服务",
          contentList: [{
            contentTitle: "权益商城",
          }, {
            contentTitle: "权益商城",
          }, {
            contentTitle: "权益商城",
          }, {
            contentTitle: "权益商城",
          }],
        }, {
          cateName: "产品服务",
          contentList: [{
            contentTitle: "权益商城",
          }, {
            contentTitle: "权益商城",
          }, {
            contentTitle: "权益商城",
          }, {
            contentTitle: "权益商城",
          }],
        }, {
          cateName: "产品服务",
          contentList: [{
            contentTitle: "权益商城",
          }, {
            contentTitle: "权益商城",
          }, {
            contentTitle: "权益商城",
          }, {
            contentTitle: "权益商城",
          }],
        },
      ],
    };
  },

  created() {

  },
  methods: {

  },
};
</script>
<style>
/* 底部栏 */
.h-footer-fn li,
.h-footer-fn ol,
.h-footer-fn ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#gamelifeFooter {
  font-family: Microsoft Yahei, simsun, arial, verdana, sans-serif;
  color: grey;
  line-height: 1.8;
}

#gamelifeFooter img {
  border: 0 none;
}

#gamelifeFooter a,
#gamelifeFooter a:hover {
  text-decoration: none;
}

.h-footer-fn,
.h-footer-group {
  min-width: 1300px;
  font-size: 12px;
  color: grey;
}

.h-footer-fn a,
.h-footer-group a {
  color: grey;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.h-footer-fn a:hover,
.h-footer-group a:hover {
  color: #ebb400;
}

.h-footer-fn {
  border-top: 2px solid #2e2e2e;
  background-color: #292929;
}

.h-footer-group {
  background-color: #1a1a1a;
  padding: 10px 0;
}

.h-footer-group img {
  vertical-align: middle;
  margin: 0 5px;
}

.h-footer,
.h-footer-about,
.h-footer-advantage {
  width: 1300px;
  margin: 0 auto;
}

.h-footer-advantage ul {
  overflow: hidden;
  padding: 43px 0 25px;
  border-bottom: 1px solid #2e2e2e;
}

.h-footer-advantage li {
  float: left;
  /* width: 160px; */
  position: relative;
  padding-left: 120px;
  min-height: 70px;
  margin-right: 40px;
}

.h-footer-advantage li i {
  position: absolute;
  left: 45px;
  top: 0;
  width: 52px;
  height: 60px;
  /* background: url(../../static/img/foot_icon.2301a58f.png) no-repeat 0 0; */
}

.h-footer-advantage li i.h-icon02 {
  background-position: -52px 0;
}

.h-footer-advantage li i.h-icon03 {
  background-position: 0 -60px;
}

.h-footer-advantage li i.h-icon04 {
  background-position: -52px -60px;
}

.h-footer-advantage strong {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
}

.h-footer-about {
  overflow: hidden;
  padding: 40px 50px;
}

.h-footer-about .h-footer-link {
  float: left;
  padding-left: 46px;
}

.h-footer-about .h-f-col {
  float: left;
  margin-right: 120px;
  line-height: 24px;
}

.h-footer-about .h-f-col .h-title {
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
}

.h-footer-about .h-f-col li {
  white-space: nowrap;
}

.h-footer-about .h-f-col:last-child {
  margin-right: 0;
}

.h-footer-about .h-icon-alipay,
.h-footer-about .h-icon-qqpay,
.h-footer-about .h-icon-weixin {
  display: block;
}

.h-footer-about .h-icon-alipay:before,
.h-footer-about .h-icon-qqpay:before,
.h-footer-about .h-icon-weixin:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  margin-right: 5px;
  /* background: url(../../static/img/foot_icon.2301a58f.png) no-repeat 0 -126px; */
}

.h-footer-about .h-icon-weixin:before {
  background-position: -26px -127px;
}

.h-footer-about .h-icon-qqpay:before {
  width: 16px;
  height: 18px;
  margin: 0 7px 0 2px;
  background-position: -85px -126px;
}

.h-footer-about .h-f-service {
  display: inline-block;
  background-color: #333;
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 10px;
}

.h-footer-about .h-f-service:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 13px;
  height: 13px;
  /* background: url(../../static/img/foot_icon.2301a58f.png) no-repeat -52px -128px; */
}

.h-footer-about .h-f-service:hover:before {
  background-position: -67px -128px;
}

.h-footer {
  text-align: left;
  color: #565656;
  overflow: hidden;
  padding: 5px 0 10px;
}

.h-footer a {
  color: #565656;
}

.h-footer .h-beian {
  margin: 0 10px;
}

.h-footer img {
  max-width: 24px;
  max-height: 26px;
}

.h-footer-about .h-footer-qrcode {
  overflow: hidden;
  margin-right: -20px;
}

.h-footer-about .h-footer-qrcode li {
  float: left;
  margin-right: 20px;
  text-align: center;
}

.h-footer-about .h-footer-qrcode img {
  width: 84px;
  height: 84px;
  vertical-align: bottom;
}

.h-friend-link {
  overflow: hidden;
  margin: 0 0 2px -5px;
  line-height: 24px;
}

.h-friend-link a {
  white-space: nowrap;
  margin: 0 5px;
  float: left;
  position: relative;
}

.h-friend-link a:before {
  content: "|";
  position: absolute;
  left: -7px;
  top: 0;
  color: #313131;
}

.h-copyright-group {
  font-size: 12px;
}

.h-copyright-group a {
  display: inline-block;
}

.h-copyright-group .img-1 {
  width: 20px;
  height: 20px;
}

.h-copyright-group .p2 {
  display: inline-block;
}

.h-copyright-group .a1 {
  margin-right: 10px;
}

.ucenter-header-group {
  height: 330px !important;
}

.ucenter-header-group .h-new-header-wrapper {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  /* background-image: url(../../static/img/ucenter_bg.60916df1.png); */
  background-size: cover;
  background-color: #2868f2;
  -webkit-box-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
}

.ucenter-header-group .h-new-header {
  background: transparent !important;
}

.ucenter-header-group .h-new-header-menu li a {
  color: hsla(0, 0%, 100%, 0.7019607843137254);
}

.ucenter-header-group .h-new-header-menu li.h-curr a,
.ucenter-header-group .h-new-header-menu li.h-curr a:hover,
.ucenter-header-group .h-new-header-menu li a:hover {
  color: #fff;
}

.footer-icon {
  width: 52px;
  height: 52px;
  color: #ffffff;
}
</style>
