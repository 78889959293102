<template>
  <div class="module-common">
    <div class="roll-swiper">
      <div class="img-box">
        <img
          alt=""
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAAASCAMAAADPJmjkAAACUlBMVEUAAAAAAAAA//8AAAAAAAAAVf8AAAAAgP8AAAAqKioqgP8kbdskkv8ggP8aGhoXi+guLi4qgOoiiO4zMzMwMDAeh/AcgPErKysxMTEji/MhhekpKSknifUxMTEvLy8kifYjhO0jjfYsLCwoh+8wMDAni/AuLi4zMzMiivEuLi4yMjIvLy8zMzMyMjImiO8pivAvLy8lhuwqi/EuLi4zMzMyMjIoi+0xMTEmiO4yMjIni/Aph+wpifAvLy8zMzMyMjIxMTEwMDAwMDAxMTEwMDAzMzMph+8oiewoie8yMjIoiu0xMTExMTEwMDAvLy8wMDAqi+4yMjIxMTEpiO8xMTEwMDAqiu8pie0pifAoiu0yMjIxMTEzMzMpie8oie0xMTEpifAzMzMpiu8xMTEoie8qiu8yMjIoiO4qivAyMjIoiu4xMTEzMzMyMjIyMjIpiu8zMzMyMjIpiu4zMzMqiu4yMjIyMjIxMTEzMzMyMjIyMjIqiu8yMjIzMzMpie4yMjIpivAyMjIpiu8zMzMqiu8qiu8yMjIpie4yMjIyMjIyMjIpiu8yMjIqiu8yMjIpiu4yMjIzMzMpi+8yMjIyMjIyMjIqivAxMTEyMjIpiu8yMjIpie8qiu8yMjIpie8zMzMzMzMzMzMpiu8zMzMzMzMyMjIyMjIqie8piu8zMzMpiu8zMzMpiu8qie8yMjIzMzMyMjIzMzMpiu8zMzMyMjIqiu4yMjIqie8zMzMpie4zMzMyMjIqiu8yMjIzMzMzMzMpiu8piu8yMjIyMjIqiu8zMzMDeyP2AAAAxHRSTlMAAQECAwMEBAUGBgcHCAoLCwwPDxAREhIVFhcZGhobHB0dHSAgISEjJScpKy0uLzI2Nzc3Nzg5OTw9QkRFRkZISUpLTk9QUVJSUlNTVFVWWlxcXV5eX2JjY2ZnaG9wc3N1eHx9f4GEhYWKi4yNjo+QkpWWl5iYmZucnZ6fn6anqKqqrq+xsrO0tLm7vLy9wcLCxMXFxsfJycrLzM7P0dLS09TV2Nna3N3e3t/i5Ojp7e/w8vLz9PT19vf3+Pn5+vv8/f3+chzFlwAAAhFJREFUOMuVkvlfTUEUwM9TlD1LskeWRMgaEq20kCj7rjxbyfZsPdJqK0U7KZVSERJRyTrn/zJn5t15d3qvj0/fH+6ZOffc750zMwAGq/1hMJNh+KxvY5v0zIi42qOmaUT+HW+ACbn5G8U06ORhCjkVsaoi/RPRy9hXMcjiqflhnAOMXaEYtoqqPBoxk+INLBdfJWJXBucDPqKQsZSnzjKNawDjmrRMHX2YhN9mUVz8A8OlSCOSp0503BupyO44wzOdv9iXTsVjXmR5hZdkAzflkhKx287pwnIK9uU8tShLYxqVvWGn9Q3biX1z5YgvaYsQVdOsGQ+pmg16awvciSwNeBmiM9NofAsr+DN07yg3olPHHJxXosL9ikn0/4EAj3a8QO8Cf+JWAM+nLcQAvhfxuhRt3+zgoBJpa7S8RBvE4J9A8efbWAmwS9/sIpfWXkyUondVkjoSzbFaA6AYH8gWFlqt0yEkXiNUikpLDO7Ky+zco9mONcKyv9QRwLqyhxR8bBprpWjJDCdjhxDZsMVCMRJ7KPjpre12PbUE96KpPXgEdJH9nEH3METH8fOUQaJgdfBvDZGvSn0fQsTP/ir8X3Q/z+A3izOLVj6rlaJoxNYawWv86BA9f2LQjzEurRWMN4nG1Bt3vdi5r/1Rrpt9kVIz95lI9hIr2ZaygsK8FILfbM89qYo1omJ0qokdPPEPshWaA0wMPmUAAAAASUVORK5CYII="
        />
      </div>
      <div class="list-box">
        <el-carousel trigger="click" height="150px">
          <el-carousel-item v-for="notice in notices" :key="notice.id">
            <div class="btn-wrap">
              <a>{{ notice.content }}</a>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <a class="more">更多 <span class="iconfont-crmeb iconyoujian2"></span></a>
    </div>
  </div>
</template>
<script>
export default {
  props: ["module"],

  data() {
    return {
      notices: [{ id: 1, content: "这是滚动公告1" }]
    };
  }
};
</script>

<style>
.roll-swiper {
  display: -ms-flexbox;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 20px;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
}

.roll-swiper .img-box img {
  width: 72px;
  height: 18px;
}

.roll-swiper .list-box {
  position: relative;
  flex: 1;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  margin-left: 20px;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 600;
}

.roll-swiper .list-box:after {
  content: " ";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 22px;
  background: #ccc;
}

.roll-swiper .more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ccc;
}

.roll-swiper .more:hover {
  color: #333;
}
</style>
