<template>
  <div @click.stop>
    <a-drawer
      :title="attrData.name"
      :visible="visibleStatus"
      :width="500"
      @close="visibleStatus = false"
    >
      <div class="right-box" v-if="!this.attrData" style="min-width: 100%;">
        <div
          style="display: flex;justify-content: center;align-items: center;height: 100vh;"
        >
          <el-empty description="点击组件可更改样式"></el-empty>
        </div>
      </div>

      <div class="right-box" v-else style="min-width: 100%;">
        <div class="mConfig-item" style="background-color: rgb(255, 255, 255);">
          <div class="mobile-config">
            <div>
              <div>
                <div class="setUpTop"></div>
                <div class="setUp">
                  <div class="label">
                    {{ this.attrData.name }}
                  </div>
                  <div class="setUp-title acea-row">
                    <div class="item on">内容</div>
                    <!-- <div class="item">样式</div> -->
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div class="setUpTop"></div>
                <div class="diy_right_title">展示设置</div>
              </div>
            </div>
            <div>
              <div class=" on mb5">
                <div class="c_row-item" style="padding: 0px 15px">
                  <div class="c_label ivu-col ivu-col-span-4">
                    选择风格
                  </div>
                  <div class="color-box ivu-col ivu-col-span-18">
                    <el-radio-group
                      v-model="style_index"
                      @change="triggerParentMethod"
                    >
                      <el-radio
                        :label="index"
                        v-for="(item, index) in this.attrData.base.sc"
                        :key="index"
                      >
                        {{ item.t }}
                      </el-radio>
                    </el-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="this.deleteStatus">
            <div class="setUpTop"></div>
            <div class="diy_right_title">内容设置</div>

            <!-- 头部 -->
            <AttrHead :attr="attrData" v-if="attrData.type == 201" />

            <!-- 轮播图 -->
            <AttrBanner :attr="attrData" v-if="attrData.type == 202" />

            <!-- 广告 -->
            <AttrGuangGao :attr="attrData" v-if="attrData.type == 208" />

            <!-- 底部设置 -->
            <AttrFooter :attr="attrData" v-if="attrData.type == 207" />

            <!-- 首页公告 -->
            <AttrCommon :attr="attrData" v-else />
          </div>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
// import "@/assets/css/attr.scss";

import AttrHead from "@/views/template/pc/module/head/style/index.vue";

//轮播图
import AttrBanner from "@/views/template/pc/module/banner/style/index.vue";

// import AttrNotice from "@/views/template/mobile/module/Notice/style/index.vue";

//广告
import AttrGuangGao from "@/views/template/pc/module/guanggao/style/index.vue";

//广告
import AttrFooter from "@/views/template/pc/module/footer/style/index.vue";

export default {
  // props: ["attrData"],
  components: {
    AttrHead,

    // AttrNotice,

    AttrBanner,

    AttrGuangGao,

    AttrFooter
  },
  data() {
    return {
      visibleStatus: false,
      attrData: [],
      style_index: undefined,
      deleteStatus: true
    };
  },

  mounted() {},
  methods: {
    //点击模块
    childMethod(data) {
      this.visibleStatus = true;

      this.deleteStatus = true;

      this.attrData = data;
      this.style_index = data.base.s;
      this.$forceUpdate();
    },

    //隐藏模块
    deleteMethod() {
      this.deleteStatus = false;
    },

    triggerParentMethod(k) {
      // 通过 $emit 向父组件发送事件
      // console.log(JSON.stringify(this.attrData))
      this.$emit("child-right", JSON.stringify(this.attrData), k);
    }
  }
};
</script>
<style>
.ant-drawer-body {
  padding: 0px !important;
}
</style>
