<template>
  <div style="background-color: #FFFFFF;">
    <el-drawer title="公共设置" :visible.sync="visibleStatus">
      <div class="right-box" style="min-width: 100%;">
        <div class="mConfig-item" style="background-color: rgb(255, 255, 255);">
          <div class="mobile-config">
            <div>
              <div>
                <div class="setUpTop"></div>
                <div class="setUp">
                  <div class="label">
                    背景设置
                  </div>
                </div>
              </div>
            </div>

            <div style="padding: 0px 10px">
              <div class="hot_imgs">
                <div class="hot_imgs">
                  <div class="list-box">
                    <a-row style="padding:10px">
                      <a-col :span="6">
                        <span>
                          背景类型
                        </span>
                      </a-col>

                      <a-col :span="18">
                        <a-radio-group v-model="attr.base.pageBg.bgType">
                          <a-radio value="1">默认背景</a-radio>
                          <a-radio value="2">自定义颜色</a-radio>
                          <a-radio value="3">自定义图片背景</a-radio>
                        </a-radio-group>
                      </a-col>
                    </a-row>

                    <a-row style="padding:10px">
                      <a-col :span="6">
                        <span>
                          背景设置
                        </span>
                      </a-col>

                      <a-col :span="18">
                        <template v-if="attr.base.pageBg.bgType == 2">
                          <el-color-picker v-model="attr.base.pageBg.bgColor" />
                        </template>

                        <!-- 自定义图片 -->
                        <template v-if="attr.base.pageBg.bgType == 3">
                          <a-button
                            type="primary"
                            @click="$refs.HImage.handleAdd()"
                          >
                            上传图片
                          </a-button>
                        </template>
                      </a-col>
                    </a-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 公共图片 -->
      <HIMAGE ref="HImage" @imageCallback="imageCallback"></HIMAGE>
    </el-drawer>
  </div>
</template>
<script>
import HIMAGE from "@/components/IMAGE/index.vue";

export default {
  components: {
    HIMAGE
  },

  data() {
    return {
      attr: {},
      visibleStatus: false
    };
  },

  mounted() {},

  methods: {
    /**
     * 点击控件
     */
    childKongJian(attr) {
      this.visibleStatus = true;
      this.attr = attr;
    },

    /**
     * 图片选择
     */
    imageCallback(row) {
      this.attr.base.pageBg.bgImage = row.imgUrl;

      this.$forceUpdate();
    }
  }
};
</script>
<style scoped>
.left {
  /* width: 350px;
  display: flex;
  background: #273239;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0; */
  height: 100vh;
  /* padding: 0px 20px 0px 0px; */
}
.left .ul1 {
  width: 80px;
  padding-top: 65px;
}
.left .ul1 li {
  color: #8e9499;
  cursor: pointer;
  height: 100px;
}
.left .ul1 li.on {
  background: #1a242b;
  color: #fff;
}
.left .ul1 li:hover {
  color: #fff;
}
.left .ul1 li i {
  font-size: 18px;
  padding-bottom: 5px;
}
.left .ul1 li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
  font-size: 14px;
}
.left .leftcon {
  flex: 1;
  height: 100%;
  background: #fff;
  overflow: hidden auto;
  border-left: 1px solid #eee;
}
.left .left-menu {
  /* padding-top: 65px; */
}
.left .left-menu /deep/ .ant-collapse-header {
  font-size: 14px;
  font-weight: bold;
  color: #444;
  padding-left: 12px;
  background-color: #ffffff;
}
.left .left-menu .hm {
  border-top: 1px solid #eee;
  padding-top: 15px;
}
.left .left-menu .item:hover {
  border-color: #29c8a1;
  box-shadow: #409eff 0 0 10px;
}
.left .left-menu .item img {
  width: 100%;
}
.left .left-menu .item {
  cursor: move;
  text-align: center;
  padding: 10px;
  border: 1px solid #c8cedc;
  /* float: left; */
  /* width: 28%; */
  margin-bottom: 15px;
  margin: 10px 10px 10px 10px;
  border-radius: 5px;
}
.left .left-control {
  /* padding-top: 65px; */
}
.left .left-control ul {
  border-top: 1px solid #eee;
  padding: 0 20px;
}
.left .left-control ul li {
  height: 50px;
  border: 1px solid #eee;
  border-radius: 5px;
  margin-top: 12px;
  display: flex;
  background: #f7f7f7;
  padding: 0 12px;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #666;
}
.left .left-control ul li .fl {
  flex: 1;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
}
.left .left-control ul li .fl.on {
  color: #29c8a1;
}
.left .left-control ul li .fr button {
  background: none;
  border: 0;
  -webkit-appearance: none;
}
.left .left-control ul li .fr button[disabled]:hover {
  cursor: not-allowed;
}
.left .left-control ul li .fr em {
  margin-left: 8px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
}
.left .left-control ul li .fr em:hover {
  color: #29c8a1;
}
.left .left-control .add-page span {
  margin-right: 12px;
  cursor: pointer;
  height: 45px;
  border: 1px solid #eee;
  margin: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #29c8a1;
  color: #fff;
  border-radius: 40px;
}
</style>
