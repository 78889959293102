<template>
  <div style="position: relative" :style="{
    backgroundColor: module.style.bgcolor
  }">
    <div class="top-bar">
      <div class="top-bar__inner">

        <a href="/" class="bar-item">
          <i class="el-icon-s-home icon"></i>
          <span class="text">商城首页</span>
        </a>

        <div class="bar-item ml">
          <i class="el-icon-tickets icon"></i>
          <span class="text">我的订单</span>
        </div>

        <span>
          <a class="bar-item">
            <i class="el-icon-user icon"></i>
            <span class="text">个人中心</span>
          </a>
        </span>

        <span style="cursor: pointer">
          <a class="bar-item">
            <i class="el-icon-headset icon"></i>
            <span class="text">联系客服</span>
          </a>
        </span>

        <span v-if="this.loginStatus">
          <a class="bar-item" style="cursor: pointer">
            <i class="el-icon-switch-button icon"></i>
            <span class="text">退出</span>
          </a>
        </span>
      </div>
    </div>

    <div class="head_nav">
      <div class="head_centent">
        <div class="header_1" :style="{
          color: module.style.color
        }">
          <a class="header_logo" style="cursor: pointer;">
            <img src="/logo.png" />
          </a>

          <div class="fa" style="width:60%">

            <span style="cursor: pointer">
              首页
            </span>
            <span style="cursor: pointer" v-for="(item, index) in module.list" :key="index">
              {{ item.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Img from "../attr/img.vue";
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      webLogo: "./logo.png",
      loginStatus: false,
      goodsName: ""
    };
  },
  computed: {},
  methods: {
    isActive(tag) {
      console.log(this.$route.path);
      // return tag === this.$route.path;
      return tag.includes(this.$route.path);
    }
  }
};
</script>

<style>
.head_centent {
  margin: 0 auto;
}

.head_nav {
  width: 100%;
  /* height: 80px; */
  /* background-color: #fff; */
  /* box-shadow: 0px 0px 10px rgb(0 0 0 / 30%); */
  z-index: 99;
  /* margin: 10px 0px 10px 0px; */
}

.head_nav .header_1 {
  height: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
}

.head_nav .header_1 .header_logo {
  width: 80px;
  height: 97.7px;
  display: flex;
  align-items: center;
}

.head_nav .header_1 .header_logo img {
  max-width: 100%;
  max-height: 100%;
}

.head_nav .header_1 .fa {
  display: flex;
  height: 100%;
  margin-top: 20px;
}

.head_nav .header_1 .fa span {
  display: flex;
  height: 100%;
  padding: 10px 10px 25px 10px;
  margin: 0 10px;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  position: relative;
}

.head_nav .header_1 .fa span.hover::after {
  content: "";
  position: absolute;
  bottom: 12px;
  left: 50%;
  /* 水平居中 */
  transform: translateX(-50%);
  /* 将元素向左平移自身宽度的一半 */
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #59f;
}

.head_nav .header_1 .fr {
  height: 40px;
  /* border: 1px solid #bbc8d5; */
  /* border: 0px; */
  /* border-radius: 50px; */
}

.head_nav .header_1 .fr form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--bs-gray-200);
  border-radius: 20px;
  overflow: hidden;
}

.head_nav .header_1 .fr form .search-focus {
  width: calc(100% - 60px);
  height: 40px;
  border: 0;
  font-size: 14px;
  text-indent: 20px;
}

.head_nav .header_1 .fr form .search-submit {
  width: 50px;
  height: 30px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #59f;
  border: 0;
  border-radius: 20px;
  margin-left: 5px;
  transition: 0.2s;
}

.head_nav .header_1 .fr form .search-submit:hover {
  background: #73abff;
}

/* 客服 */
.customer-service {
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index: 9999;
}

.service-button,
.faq-button {
  background-color: #12b7f5;
  color: #fff;
  font-size: 14px;
  padding: 20px 50px;
  border: none;
  border-radius: 50px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.service-icon {
  transform: scale(1.2);
  /* 调整图标的放大倍数 */
}

/* 导航top */

.top-bar {
  /* background-color: #f4f5f6; */
  width: 100%;
  /* height: 0.7rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #f5f5f5; */
  background: #333;
}

.top-bar .top-bar__inner {
  width: 100%;
  height: 36px;
  margin: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 13px;
}

.top-bar .top-bar__inner .bar-item {
  cursor: pointer;
  display: flex;
  /* align-items: center; */
  /* color: #6c6c6c; */
  color: #b0b0b0;
  padding: 0 8px;
}

.top-bar .top-bar__inner .bar-item.mr {
  margin-right: auto;
}

.top-bar .top-bar__inner .bar-item.ml {
  margin-left: auto;
}

.top-bar .top-bar__inner .bar-item img {
  height: 14px;
  margin-right: 2px;
}

.search-container {
  display: flex;
  align-items: center;
}

.search-input {
  margin-right: -1px;
  /* 去除输入框的右边边框 */
  border-radius: 0;
  /* 去除输入框的圆角 */
}

.search-btn {
  border-radius: 0 4px 4px 0;
  /* 设置按钮的右边圆角 */
}

.icon {
  font-size: 18px;
  margin-right: 6px;
}

.text {
  font-size: 14px;
}
</style>
