<template>
  <div class="module-common">
    <div>
      <div class="product_index2">
        <div class="Tab--tabWrapAll--IlpEnd4">
          <div class="Tab--tabWrap--usAaiUA">
            <div class="Tab--baseStyle--izOIUCL Tab--actStyle--bq3TL6m">
              所有商品
              <div class="Tab--underline--V58GBfS"></div>
            </div>
          </div>
          <div class="Tab--line--uvEgww5"></div>
        </div>
        <el-tabs
          v-model="activeName"
          class="goods-index2-tabs"
          @tab-click="handleClick"
        >
          <div class="product_line"></div>
          <el-tab-pane
            v-for="(value, index) in catesList"
            :label="value.categoryName"
            :key="index"
            :name="value.categoryName"
            :value="value.id"
          >
            <!-- 二级目录 -->
            <div>
              <div style="padding: 10px 0px">
                <span
                  v-for="(item, index) in value.children"
                  :key="index"
                  class="index_2_subName"
                >
                  <span>
                    {{ item.categoryName }}
                  </span>
                </span>
              </div>
            </div>

            <div style="margin-bottom: 20px;">
              <el-row :gutter="10">
                <el-col :span="4" v-for="(v, k, i) in goodsList" :key="i">
                  <div class="px-goods-card">
                    <a-empty
                      image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                    >
                      <span slot="description"> 暂无数据 </span>
                    </a-empty>

                    <div class="goods2_content">
                      <div style="height: 45px; overflow: hidden">
                        {{ v.productName }}
                      </div>
                      <div style="margin: 10px 0px"></div>
                      <div
                        style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 15px;
                          "
                      >
                        <div
                          class="index2_price"
                          style="font-size: 18px;line-height: 14px;color: #ff4f42;"
                        >
                          <span>￥1.00</span>
                        </div>

                        <span
                          style="
                              border: 1px solid #e1c1c7;
                              background-color: #fef4f3;
                              display: inline-block;
                              height: 26px;
                              padding: 0 10px;
                              line-height: 24px;
                              font-size: 12px;
                              color: #d57081;
                              border-radius: 20px;
                            "
                        >
                          立即购买
                        </span>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
// import Img from "../attr/img.vue";
export default {
  props: ["module"],
  computed: {
    moduleBgStyle() {
      return {
        background: `linear-gradient(122235deg, ${
          this.module.moduleBg.bgColor
        }, ${this.module.moduleBg.fzColor})`,
        color: this.module.moduleBg.fontColor
      };
    }
  },
  components: {},
  data() {
    return {
      webLogo: "",
      loginStatus: false,
      goodsName: "",
      activeName: "影视会员",
      catesList: [
        {
          categoryName: "影视会员",
          children: [
            {
              categoryName: "PP视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "哔哩哔哩",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1642616483.jpg"
            },
            {
              categoryName: "芒果TV",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1693224309.jpg"
            },
            {
              categoryName: "优酷视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1603739972.jpg"
            }
          ]
        },
        {
          categoryName: "音频会员",
          children: [
            {
              categoryName: "网易云音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1604095683.jpg"
            },
            {
              categoryName: "喜马拉雅",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1459846552.jpg"
            },
            {
              categoryName: "酷我音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1889239183.jpg"
            },
            {
              categoryName: "酷狗音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1677053753.jpg"
            },
            {
              categoryName: "网易云音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1604095683.jpg"
            }
          ]
        },
        {
          categoryName: "美食餐饮",
          children: [
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            }
          ]
        },
        {
          categoryName: "商务办公",
          children: [
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            }
          ]
        }
      ],
      goodsList: [
        {
          picUrl: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          price: "2.5"
        },
        {
          picUrl: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          price: "2.5"
        },
        {
          picUrl: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          price: "2.5"
        }
      ]
    };
  },

  methods: {
    isActive(tag) {
      console.log(this.$route.path);
      // return tag === this.$route.path;
      return tag.includes(this.$route.path);
    }
  }
};
</script>

<style>
.product_index2 {
  /* transition: 0.6s; */
  background-color: #fff;
  /* border: 1px solid #f2f2f2; */
  line-height: 42px;
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
}

.product_index2 .label {
  transition: 0.2s;
  cursor: pointer;
  font-size: 16px;
}

.product_index2 .label:hover {
  color: #14d3b7;
}

.px-goods-card {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 15px;
  overflow: hidden;
  border: 1px solid #f2f2f2;
  margin-top: 10px;
}

.px-goods-card .goods2_content {
  line-height: 24px;
  font-size: 14px;
}

.px-goods-card .goods2_content .index2_price {
  height: 24px;
  font-size: 18px;
  align-items: baseline;
  color: #ff4f42;
  /* font-size: 14px;
  display: flex;
  color: #f44336;
  align-items: center;
  margin-bottom: 20px; */
}

.px-goods-card:hover {
  border: 1px solid #14d3b7;
}

/deep/.el-dialog {
  border-radius: 5px;
}

/* 美化tabs */
.goods-index2-tabs {
  padding: 0px 10px;
}

.goods-index2-tabs .el-tabs__header {
  background-color: #ffffff;
  border-radius: 20px;
  margin: 0px;
  /* margin-bottom: 20px; */
}

.goods-index2-tabs .el-tabs__item {
  color: #606266;
  border: none;
  height: 50px;
  line-height: 50px;
  /* margin-right: 10px; */
  font-size: 16px;
}

.goods-index2-tabs .el-tabs__item.is-active {
  background-color: rgb(246, 246, 246);
  color: #ff5f00;
  border-radius: 12px 12px 0px 0px;
  font-size: 16px;
  font-weight: bold;
  /* padding: 12px 16px; */
}

.goods-index2-tabs .el-tabs__pane {
  padding: 10px;
}

.goods-index2-tabs .el-tabs__nav {
  padding: 0px;
}

/* .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 15px !important;
}

.el-tabs--top .el-tabs__item.is-top:last-child {
  padding-right: 15px !important;
} */

.goods-index2-tabs .el-tabs__active-bar {
  background-color: transparent !important;
}

.goods-index2-tabs .el-col-5 {
  width: 20%;
}

.f-hd {
  /* color: #8d8e99; */
  text-align: center;
  height: 100%;
  white-space: nowrap;
  font-size: 16px;
  /* font-weight: 700; */
}

/* 新的 */

.Tab--tabWrapAll--IlpEnd4 {
  margin-bottom: 12px;
  margin-top: 16px;
  width: auto;
}

.Tab--tabWrap--usAaiUA {
  background: #fff;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.Tab--tabNav--w5p1VWB {
  border-bottom: 1px solid #fff !important;
  height: 74px;
}

.Tab--baseStyle--izOIUCL {
  color: #000;
  cursor: pointer;
  font-family: AlibabaSans102v1TaoBao-Bold, Alibaba-Sans102, PingFangSC-Regular;
  font-size: 18px;
  letter-spacing: 0;
  margin: 0 7px 0 18px;
}

.Tab--actStyle--bq3TL6m {
  font-weight: 700;
}

.Tab--underline--V58GBfS {
  background-color: #f50;
  border-radius: 10px;
  height: 3px;
  margin: 17px auto 0;
  width: 80%;
}

.Tab--line--uvEgww5 {
  border-top: 1px solid hsla(0, 0%, 59%, 0.1);
}

.P4P--iframeBox--idJOjlK {
  background-color: #fff;
  width: 100%;
}

.P4P--iframeBodyWrap--r9wPZel {
  margin: 0 auto;
  width: 885px;
}

.P4P--iframeBody--hZ29in3 {
  height: 150px;
  margin-bottom: 0.5px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.P4P--iframeContent--r55cnrH {
  left: -92px;
  position: absolute;
  top: -15px;
  -webkit-transform: scale(0.827);
  -ms-transform: scale(0.827);
  transform: scale(0.827);
}

.index_2_subName {
  color: rgb(0 0 0);
  /* text-align: center; */
  border-radius: 5px;
  background-color: rgba(0, 30, 89, 0.04);
  margin-right: 10px;
  /* margin-top: 116px; */
  padding: 10px 10px;
}

.subNameClass {
  color: #ffffff;
  text-align: center;
  border-radius: 5px;
  background-color: #1a87ff;
  margin-right: 10px;
  /* margin-top: 16px; */
  padding: 10px 10px;
}

.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 16px !important;
}
</style>
