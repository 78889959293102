<template>
  <div class="module-common">
    <div class="index-3" v-for="(value, key, index) in catesList" :key="index">
      <div class="public-index-list">
        <div class="wrap">
          <div class="item ing">
            <div class="title">{{ value.categoryName }}</div>
            <div class="list">
              <a v-for="(item, key, index) in value.children" :key="index">
                <van-image width="50" height="50" :src="item.prcUrl" round />
                <span>{{ item.categoryName }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Img from "../attr/img.vue";
export default {
  props: ["module"],
  computed: {
    moduleBgStyle() {
      return {
        background: `linear-gradient(122235deg, ${this.module.moduleBg.bgColor
          }, ${this.module.moduleBg.fzColor})`,
        color: this.module.moduleBg.fontColor
      };
    }
  },
  components: {},
  data() {
    return {
      webLogo: "",
      loginStatus: false,
      goodsName: "",
      catesList: [
        {
          categoryName: "影视会员",
          children: [
            {
              categoryName: "PP视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "哔哩哔哩",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1642616483.jpg"
            },
            {
              categoryName: "芒果TV",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1693224309.jpg"
            },
            {
              categoryName: "优酷视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1603739972.jpg"
            }
          ]
        },
        {
          categoryName: "音频会员",
          children: [
            {
              categoryName: "网易云音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1604095683.jpg"
            },
            {
              categoryName: "喜马拉雅",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1459846552.jpg"
            },
            {
              categoryName: "酷我音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1889239183.jpg"
            },
            {
              categoryName: "酷狗音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1677053753.jpg"
            },
            {
              categoryName: "网易云音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1604095683.jpg"
            }
          ]
        },
        {
          categoryName: "美食餐饮",
          children: [
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            }
          ]
        },
      ]
    };
  },

  methods: {
    isActive(tag) {
      console.log(this.$route.path);
      // return tag === this.$route.path;
      return tag.includes(this.$route.path);
    }
  }
};
</script>

<style>
/* 新的 */
.public-index-list .wrap .item {
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 5%);
  padding: 20px;
  border-radius: 8px;
}


.public-index-list .wrap .item .title {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  text-indent: 15px;
  height: 24px;
  line-height: 24px;
  color: black;
}

.public-index-list .wrap .item.ing .title::after {
  background-color: #5599ff;
  background-image: linear-gradient(to bottom, #5599ff, #1ba4ff);
}

.public-index-list .wrap .item .title::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 5px;
  border-radius: 5px;
  background: #ccc;
}

.public-index-list .wrap .item .list {
  display: flex;
  flex-wrap: wrap;
}

.public-index-list .wrap .item .list a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 16.666666%;
  margin-top: 30px;
}

.public-index-list .wrap .item .list a img {
  width: 60px;
  height: 60px;
  border-radius: 40px;
  box-shadow: 0px 5px 15px rgb(0 184 255 / 20%);
  padding: 5px;
  background: #fff;
}

.public-index-list .wrap .item .list a span {
  font-size: 14px;
  margin-top: 10px;
  color: #333;
}
</style>
