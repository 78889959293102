<template>
  <div class="module-common">
    <div class="model_guanggao">
      <el-row :gutter="10" v-if="module.list.length > 0">
        <el-col
          v-for="(item, index) in module.list"
          :key="index"
          :span="24 / module.max"
        >
          <div class="model_guanggao_img">
            <div class="btn-wrap">
              <a><img :src="item.imageUrl" class="model_guanggao_Banner"/></a>
            </div>
          </div>
        </el-col>
      </el-row>

      <template v-else>
        <div style="text-align: center;">
          请点击添加广告内容
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ["module"],
  data() {
    return {};
  },

  methods: {}
};
</script>

<style>
.model_guanggao {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 126px;
}

.model_guanggao_Banner {
  border-radius: 5px;
  width: 100%;
  height: 126px;
  -webkit-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
}
</style>
