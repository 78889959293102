<template>
  <div>
    <div class="init-container" style="background: #3b8cfe;    padding: 10px;">
      <div class="layui-container" v-loading.fullscreen.lock="fullscreenLoading">

        <div v-if="dialogVisible">
          <h1 class="install-h1">安装成功</h1>

          <div class="layui-elem-quote">
            <div class="protocol">
              <el-form >
                <el-form-item label="商城地址：">
                  <el-button type="text">http://{{ dataForm.domains }}</el-button>
                </el-form-item>

                <el-form-item label="管理地址：">
                  <el-button type="text">http://{{ dataForm.domains }}/admin</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>


        <div v-else>
          <h1 class="install-h1">换换云商城 程序安装</h1>

          <div>
            <el-form ref="form" :model="dataForm" label-width="80px">
              <el-form-item label="登录账号:">
                <el-input v-model="dataForm.username" placeholder="请设置商城后台管理账号"></el-input>
              </el-form-item>

              <el-form-item label="登录密码:">
                <el-input v-model="dataForm.password" placeholder="请设置商城后台管理密码" show-password></el-input>
              </el-form-item>

              <el-form-item label="绑定域名:">
                <el-input v-model="dataForm.domains" placeholder="请绑定商城访问域名"></el-input>
              </el-form-item>
            </el-form>
          </div>

          <div class="layui-elem-quote">
            <div class="protocol">
              <h3>换换云 系列产品许可协议</h3>
              <p>
                欢迎使用换换云系列产品。以下条款和条件构成您与换换云就软件使用许可所达成的协议。
              </p>
              <p>用户安装和使用此产品，即意味着同意以下条款和条件。</p>

              <h4>提示条款：</h4>
              <p>
                本协议是您与沁阳市换换科技有限公司共同签署。
                <br />换换云电商系统（以下称“换换云”），由沁阳市换换科技有限公司（以下称“换换科技”）独创开发
                <br />版权所有Copyright
                (c)2016-2024，换换科技保留所有权利，换换云官方对此拥有最终修改权和解释权。
                <br />
                <br />在使用换换云电商系统（以下称“许可软件”或“本软件”）之前，请您仔细阅读本协议，特别是法律适用和争议解决条款，此等条款将以粗体标识，您需要重点阅读。如您对协议有任何疑问，可向客服咨询。如果您已下载、复制、安装或以其他任何方式使用该软件，则视为已经接受本协议。如果您不接受本协议的全部或部分条款，您将无权使用本软件。请立即终止安装、或以其他方式使用该软件，删除您已经安装或保留的该软件的任何组件。
                <br /><br />由于互联网高速发展，您与我们签署的本协议列明的条款并不能完整罗列并覆盖您与我们所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。
                <br /><br />因此，《版权声明》及其他规则均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用许可软件，视为您同意上述补充协议。我们如修改本协议或其补充协议，协议条款修改后，请您仔细阅读并接受修改后的协议后再继续使用许可软件。
              </p>
              <p>
                <br />
              </p>
              <p></p>
              <h4>一、定义</h4>
              <br />
              <p>
                软件（许可软件或本软件）：本协议中的“软件”是指换换云电商系统，由若干模块或功能组成的已经植入或即将植入换换科技指定产品内的信息处理程序或支持文件，其中支持文件具体包括软件的源代码、目标码以及相关软件中所包含的图片、照片、图标、动画、录音、录像、音乐、文字、代码的全部或部分，还包括与许可软件或产品相关的所有描述其功能、特点、内容、质量、测试、用户手册、用户许可协议等纸质或电子版的资料、技术文档等。
              </p>
              <p>
                您：本协议中的“您”是指取得换换科技合法许可使用本软件权利的个人或单个法人实体，法人实体包括公司、企业、机构、组织或单位。
              </p>
              <p>
                我们：本协议中的“我们”即是换换云官方，是指换换科技，即沁阳市换换科技有限公司及其关联公司。
              </p>
              <p>
                二次开发：本协议中的“二次开发”在现有的软件上进行定制修改，如对功能扩展，达到您想要的功能，原则不得改变本软件原有系统内核及系统设定的框架，我们允许的二次开发仅指对部分软件界面、功能删改或扩展，并非对内核及框架进行实质性修改。
              </p>
              <p>
                <br />
              </p>
              <p></p>
              <h4>二、软件许可使用内容</h4>
              <br />
              <p>
                在您遵守本协议内容的前提下，您通过我们指定合法渠道购买软件商用许可后，换换科技授予您的商用许可权利包括：
              </p>
              <p>
                1、安装和使用权利：您可以为商用目的安装和使用本软件，使用本软件提供的全部功能。
              </p>
              <p>
                2、绑定唯一域名的权利：在安装本软件前，您应当自备一个域名并告知我们，以便我们将域名与本软件进行绑定。该绑定域名是商用许可软件的唯一指向。您应确保域名的唯一性、有效性，域名一经绑定，不得随意更换。您自备的域名可以是顶级、二级、或三级域名，您应对域名合法性、有效性承担责任。在使用本软件过程中，如需更换域名，应提前三个工作日以书面方式告知我们并如实告知被更换域名存在的问题，否则，我们有权利不予更换。
              </p>
              <p>
                3、申请商用授权的权利：您在我们指定合法渠道购买软件商用许可后，凭借订单号可以在我们官网申请商用授权，并通过我们官方网站下载授权证书。
              </p>
              <p>
                4、获取商用授权证书的权利：通过我们官方网站下载的商用授权证书是许可您将软件商用的合法凭证。该授权证书是授予您以本协议约定方式合法使用软件的永久授权，但我们不对授权作无限制使用的永久承诺。
              </p>
              <p>
                5、授权内容使用权：在取得我们许可后，您拥有使用本软件构建的网站使用权，并独立承担与之相关法律义务。您可以在协议规定的约束条件下和限制范围内修改
                换换云
                源代码或界面风格以适应您的网站要求，但应保留我们的版权信息。不管你的网站是否整体使用
                换换云 ，还是部份栏目使用 换换云，在你使用了 换换云
                的网站主页上必须加上 换换云 官方网址(www.huanhuanyun.com)的链接。
              </p>
              <p>
                6、在获得商业授权之后，您才可以将本软件应用于商业用途，同时依据所购买的授权类型确定技术支持内容。商业授权用户享有反馈意见和提出建议的权力，相关意见和建议将在我们下一次软件升级中被优先考虑，但我们对此不作承诺或保证。
              </p>
              <p>
                7、著作权受到法律和国际公约保护。未经我们书面许可，不得删除网站底部及相应的官方链接。购买商业授权请联系换换科技了解最新说明。
              </p>
              <p>
                8、本软件适用运营环境，在软件相关文档中已经明确提示，如因软件安装不符运营环境造成的故障，我们不承担任何责任。
              </p>
              <p>
                <br />
              </p>
              <p></p>
              <h4>三、权利限制</h4>
              <br />
              <p>
                1、单一使用限制：同一个域名，只允许绑定一次。您购买的许可，只允许您自己使用，不得再许可任何第三人使用。
              </p>
              <p>
                2、共享软件限制：您不得通过共享软件的全部或部分，允许多人使用软件的部分或全部功能。
              </p>
              <p>
                3、软件分解限制：您不得通过分解软件，把不同功能或把软件的不同部分嵌入到其他软件系统。
              </p>
              <p>
                4、软件完整性限制：您不得删除软件中的任何版权申明、提示，亦不得对软件中出现的任何商标或标识进行涂抹、修改或删除，除非已经获得我们的书面同意，您应将需要修改的标识等详细情况书面告知我们，以便我们评估您的需要。
              </p>
              <p>
                5、反向工程、反编译、反汇编限制：您不得对软件进行反向工程、反编译、反汇编，除非法律明确规定允许这些行为除外。
              </p>
              <p>
                6、转让限制：未经换换科技的书面同意，您不得公开、转让、出租、出借、再许可、分发该软件的全部或任何部分或软件单一备份副本给第三方。
              </p>
              <p>
                7、保密限制：未经换换科技书面同意，您不得将本软件的性能或其他任何评估、测试结果、技术秘密透露给任何第三方。
              </p>
              <p>
                <br />
              </p>
              <p></p>
              <h4>四、权利保留</h4>
              <br />
              <p>
                1、换换科技依法保留未在本协议中明确授予给您的其他一切在法律上属于换换科技的权利。
              </p>
              <p>
                2、本软件受著作权法、国际著作权条约和其他的知识产权法律或国际条约保护。根据本协议，在此仅许可您非独占性的、非排他性的一般许可使用该软件的权利，而不是出售或转让。
              </p>
              <p>
                3、商标权：本协议不授予您换换科技或其供应商的任何商标或服务标志相关的任何权利。
              </p>
              <p>
                4、本软件所涉及到的一切知识产权，包括但不限于专利权、著作权、商标权、商业秘密、技术秘密，均属于各自内容拥有者的财产，换换科技保留从其所拥有的知识产权获取利益的权利。
              </p>
              <p>
                5、未经我们书面许可，不得对本软件或与之关联的商业授权进行出租、出售、抵押或发放子许可证。
              </p>
              <p>
                6、未经我们书面许可，禁止在 换换云
                的整体或任何部分基础上以发展任何派生版本、修改版本或第三方版本用于重新分发。
              </p>
              <p>
                7、您一旦开始确认本协议并安装
                换换云，即被视为完全理解并接受本协议的各项条款，在享有上述条款授予的权力的同时，受到相关的约束和限制。协议许可范围以外的行为，将直接违反本授权协议并构成侵权，我们有权立即终止授权，责令停止损害，并保留追究相关责任的权力。
              </p>
              <p>
                <br />
              </p>
              <p></p>
              <h4>五、知识产权</h4>
              <br />
              <p>
                1、我们拥有许可软件的著作权、商业秘密以及其他相关的知识产权，包括与许可软件有关的各种文档资料。许可软件的相关标识属于我们及我们的关联公司的知识产权，并受到相关法律法规的保护。
              </p>
              <p>
                2、在未获得我们明确同意前，您不得复制、模仿、使用或发布上述图标，也不得修改或删除应用产品中体现我们及其关联公司的任何标识、图标或身份信息。
              </p>
              <p>
                3、未经我们及我们的关联公司事先书面同意，您不得为任何营利性或非营利性的目的自行实施、利用、转让或许可任何第三方实施、利用、转让上述知识产权。
              </p>
              <p>
                4、除非在此明确地许可或授予，本协议并不涉及任何技术转让，软件里所包含和涉及所有权利，产权和利益属于我们独自所有。除非在此明确地许可，本合同并不将任何技术转让给您。
              </p>
              <p>
                <br />
              </p>
              <p></p>
              <h4>六、升级版本</h4>
              <br />
              <p>
                1、我们会根据需要在后续进行一系列免费升级操作，您只有在获得商业使用授权许可后，才享有软件免费升级权益。我们有权决定将升级包何时以何种方式发送给您。
              </p>
              <p>
                2、升级版本的许可：如果该软件经换换科技同意升级，除非升级版本有替代的软件许可协议，否则升级版本仍应遵循本协议条款。
              </p>
              <p>3、不论软件是否升级，您必须遵守本协议。</p>
              <p>
                <br />
              </p>
              <p></p>
              <h4>七、无担保和责任限制</h4>
              <br />
              <p>
                1、除换换科技明确明示保证的事项以外，对其他任何默示、特定用途、适销性不做任何默示或明示的保证，由此引起的风险由您自己承担。
              </p>
              <p>
                2、有关本软件在使用过程中存在不适用性情况，您应当立即以书面方式反馈给我们，在我们现有技术可以解决的情况下，将依照换换科技的软件产品标准售后政策规定。
              </p>
              <p>
                1)换换科技不对试用期及免费试用软件因使用而产生的损失承担任何明示或暗示的责任。
              </p>
              <p>2)换换科技承担的所有责任以您购买该软件所支付的价款为限。</p>
              <p>
                3、对因意外事故、滥用、错误使用、擅自修改所引起的软件使用问题，我们不承担任何责任，也不做任何保证。对因软件产品存在被攻击，或者自然灾害等不可抗力因素或非换换科技原因导致软件不能使用，或造成损失的，我们不承担任何责任，也不做任何保证。
              </p>
              <p>
                4、对因使用软件引起的其他任何附带的、间接的或惩罚性的损失，包括但不限于商业利润的损失、信息或数据的丢失，换换科技不承担任何责任，即使换换科技已被告知存在此种损害的可能性也不例外。
              </p>
              <p>
                5、除法律法规有明确规定外，我们将尽最大努力确保许可软件及其所涉及的技术及信息安全、有效、准确、可靠，但受限于我们现有技术，您充分理解我们不能对此进行担保。您理解，对于因您自身、不可抗力及第三方原因导致的您的直接或间接损失，我们无法承担责任。
              </p>
              <p>
                6、由于您因下述任一情况所引起或与此有关的人身伤害或附带的、间接的损害赔偿，包括但不限于利润损失、资料损失、业务中断的损害赔偿或其他商业损害赔偿或损失，需由您自行承担：使用或未能使用许可软件；第三方未经批准的使用许可软件或更改您的数据；使用许可软件进行的行为产生的费用及损失；您对许可软件的误解；非因我们的原因而引起的与许可软件有关的其他损失。
              </p>
              <p>
                7、非经我们或我们授权开发并正式发布的其他任何由许可软件衍生的软件均属非法，下载、安装、使用此类软件，或未经绑定唯一指向域名，可能导致不可预知的风险，由此产生的法律责任与纠纷与我们无关，我们有权中止、终止使用许可和（或）其他一切服务。
              </p>
              <p>
                8、您与其他使用许可软件的用户之间通过许可软件进行时，因您受误导或欺骗而导致或可能导致的任何心理、生理上的伤害以及经济上的损失，均应由侵权方依法承担所有责任。
              </p>
              <p>
                <br />
              </p>
              <p></p>
              <h4>八、保密条款</h4>
              <br />
              <p>
                双方都应为可能获知另一方的商业计划、客户方资料、技术、产品、代码、文档和其他作为该方商业秘密的秘密信息予以保密。秘密信息包括所有有形的或无形的、标明为秘密的信息。秘密信息归披露方所有，除非经披露方声明许可否则不得披露或使用。
              </p>
              <p>
                <br />
              </p>
              <p></p>
              <h4>九、协议终止和违约责任</h4>
              <br />
              <p>
                1、如果您没有遵守本协议的部分或全部条款，换换科技可以随时单方终止本协议。协议终止后，我们将取消对您的商用许可授权，同时您必须立即停止使用该软件，对已经安装的软件进行卸载，如果由于您违反了本协议的规定给换换科技造成损失，应承担损失赔偿责任。
              </p>
              <p>
                2、您应理解按授权范围使用许可软件、尊重软件及软件包含内容的知识产权、按规范使用软件、按本协议约定履行义务是您获取我们授权使用软件的前提，如您违反本协议，我们有权终止使用许可。
              </p>
              <p>
                3、您对软件的使用有赖于我们及关联公司为您提供的配套服务，您违反与我们或我们关联公司的条款、协议、规则、通告等相关规定，我们有权终止使用许可。您违反了本协议的规定给换换科技造成损失，应承担给我们造成损失的赔偿责任。
              </p>
              <p>
                4、您理解出于维护软件系统及软件平台秩序的目的，如果您向我们及（或）我们的关联公司作出任何形式的承诺，且相关公司已确认您违反了该承诺并通知我们依据您与其相关约定进行处理的，则我们可按您的承诺或协议约定的方式对您的使用许可及其他我们可控制的权益采取限制措施，包括中止或终止对您的使用许可，并追究您相关法律责任的权利。
              </p>
              <p>
                5、您如从获得我们授权认可的第三方取得许可软件，您需要遵守本协议及第三方对您使用许可软件方式与限制的约定，如果您违反本协议及与第三方约定，我们有权终止对您的使用许可，并追究您相关法律责任。
              </p>
              <p>
                6、您应对从本软件获得的代码、文档等技术信息保密，不得对源代码、文档及框架进行删改，不得破译加密部分，不得非法进行倒卖本软件，我们不对非法软件使用后果承担任何责任，并有权追究您的法律责任，您应当赔偿因您的侵权行为给我们造成的直接和间接损失。
              </p>
              <p>
                7、如您违反本协议规定的条款，则构成违约，应当承担软件销售价格十倍至五十倍不等的违约金，如给我们或其他用户造成损失的，您必须承担全部的赔偿责任（包括直接损失和间接损失），包括但不限于咨询费、诉讼费、执行费、保全费、保险费、律师费等费用。
              </p>
              <p>
                <br />
              </p>
              <p></p>
              <h4>十、管辖法律及可分割性</h4>
              <br />
              <p>
                1、本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，如无相关法律规定的，则应参照通用国际商业惯例和（或）行业惯例。本协议由您与我们于我们所在地江苏省扬州市广陵区签署。因本协议产生或与本协议有关的争议，您可与我们以友好协商，协商不成时，提交扬州仲裁委员会予以裁决。仲裁裁决是终局的，对双方均有拘束力。
              </p>
              <p>
                2、本协议任何条款被认定为无效的，不应影响其他条款或其任何部分的效力，您与我们仍应善意履行。
              </p>
              <p>
                <br />
              </p>
              <p></p>
              <h4>十一、信息说明</h4>
              <br />
              <p>1.换换云产品没有收集任何最终用户的个人隐私信息。</p>
              <p>
                2.为了保障您使用换换云的产品与/或服务稳定性和版权合法性我们需要收集您的设备信息（操作系统及软件版本、安装站点域名、IP地址、浏览器信息）。
              </p>
              <p>
                <br />
              </p>
              <p></p>
              <h4>十二、其他</h4>
              <br />
              <p>1、本协议未约定的，由双方另行商定。</p>
              <p>
                2、本协议的所有标题仅是为了醒目及阅读方便，本身并没有实际涵义，不能作为解释本协议涵义的依据。（正文完）
              </p>
              <p>
                <br />
              </p>
              <p></p>
              <p>沁阳市换换科技有限公司</p>

              <span>您一旦选择下载安装或使用换换云，即被视为完全理解并接受本协议的各项条款和告知，在享有上述条款授予权力的同时，也受到相关的约束和限制。
                <span>本项目包含的第三方源码和二进制文件之版权信息另行标注。
                  <span>换换云拥有最终解释权，任何站点使用本软件则表示默认接受此协议。
                  </span></span></span>
            </div>
          </div>
          <div class="btn-box">
            <el-button type="primary" @click="loadData()">立即安装</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { install } from "@/api/index";

export default {
  data() {
    return {
      dialogVisible: false,
      dataForm: {
        username: undefined,
        password: undefined,
        domains: undefined,
      },
      fullscreenLoading: false,
    };
  },

  methods: {
    /**
     * 安装
     */
    loadData() {
      this.fullscreenLoading = true;
      install(this.dataForm)
        .then(res => {
          console.log(res)
          this.$message({
            message: "恭喜你，安装成功",
            type: "success",
          });
          this.dialogVisible = true;
          this.fullscreenLoading = false;
        })
        .catch(error => {
          console.error(error);
          this.fullscreenLoading = false;
        })
        .finally(() => {
          this.fullscreenLoading = false;
        });
    },
  },
};
</script>

<style>
.init-container {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  min-height: 100vh;
}

.init-icon i {
  font-size: 5em;
  color: #007bff;
}

.init-text h1 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-left: 10px;
  padding: 0;
}

/* 新的 */

.layui-container {
  max-width: 900px !important;
  margin: auto;
  padding: 20px;
  background: linear-gradient(135deg, #fff, rgba(255, 255, 255, 0.5) 50%);
  border-radius: 8px;
}

.layui-container .install-h1 {
  color: #393d49;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 14px;
}

.layui-elem-quote {
  margin-bottom: 20px;
}

.layui-elem-quote {
  margin-bottom: 10px;
  padding: 15px;
  line-height: 1.8;
  border-radius: 0 2px 2px 0;
  background-color: #fafafa;
}

.layui-elem-quote h1 {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 20px;
}

.btn-box {
  text-align: center;
  margin: 15px 0;
}

.layui-elem-quote>p {
  margin-bottom: 10px;
}

.layui-elem-quote>p a {
  color: #1e9fff;
}

.layui-elem-quote>p a:hover {
  color: #e86c6c;
}

.protocol {
  width: 100%;
  height: 300px;
  padding: 10px 20px;
  background-color: #fff;
  overflow-y: scroll;
  box-sizing: border-box;
}

.protocol h3 {
  text-align: center;
  font-size: 18px;
  margin: 10px 0 15px;
}

.protocol h4 {
  font-size: 16px;
  margin-top: 10px;
  color: #333;
}

.protocol p {
  line-height: 1.7;
  color: #555;
}
</style>
