<template>
  <div>
    <div class="feature-box">
      <div class="soft-header-title" style="margin-bottom: 0px;">
        <div class="title">精选推荐</div>
        <!-- <div class="feature-box-button">换一换</div> -->
      </div>
      <div class="soft-box">
        <el-row :gutter="5">
          <el-col :span="6" v-for="(data, index) in listData" :key="index">
            <div class="soft-item">
              <a-empty
                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
              >
                <span slot="description"> 暂无数据 </span>
              </a-empty>
              <div class="soft-info">
                <div class="soft-name">
                  <div class="title" style="max-width: 164px;">
                    {{ data.productName }}
                  </div>
                </div>
                <div class="soft-desc" style="width: 164px;margin-top: 15px;">
                  1.00
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["module"],
  computed: {},
  data() {
    return {
      listData: [
        {
          productImg: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          productPrice: "2.5"
        },
        {
          productImg: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          productPrice: "2.5"
        },
        {
          productImg: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          productPrice: "2.5"
        },
        {
          productImg: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          productPrice: "2.5"
        },
        {
          productImg: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          productPrice: "2.5"
        },
        {
          productImg: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          productPrice: "2.5"
        },
        {
          productImg: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          productPrice: "2.5"
        },
        {
          productImg: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          productPrice: "2.5"
        }
      ]
    };
  }
};
</script>

<style scoped>
.feature-box {
  padding: 30px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 8px;
}

.soft-header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  margin-bottom: 20px;
}

.soft-header-title .title {
  color: #000;
  font-family: "PingFang SC";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.soft-header-title .feature-box-button {
  color: #1232da;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  cursor: pointer;
}

.feature-box .soft-box {
  display: flex;
  flex-wrap: wrap;
}

.feature-box .soft-item {
  display: flex;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex: 1 1 0; */
  padding: 12px 20px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: #fff;
  width: 276px;
  height: 90px;
  margin-right: 12px;
  box-sizing: border-box;
  margin-top: 20px;
}

.feature-box .soft-item .soft-img {
  width: 60px;
  height: 60px;
  margin-right: 12px;
}

.feature-box .soft-item .soft-name {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  height: 20px;
  margin-right: 5px;
}

.feature-box .soft-item .title {
  color: #181818;
  font-family: "PingFang SC";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.feature-box .soft-item .tag {
  display: flex;
  padding: 1px 4px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  color: #00ab59;
  font-family: "PingFang SC";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 2px;
  background: rgba(0, 179, 93, 0.1);
  margin-right: 3px;
}

.feature-box .soft-item .tag {
  display: flex;
  padding: 1px 4px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  color: #00ab59;
  font-family: "PingFang SC";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 2px;
  background: rgba(0, 179, 93, 0.1);
  margin-right: 3px;
}

.feature-box .soft-item .tag img {
  width: 8px;
  height: 8px;
  margin-right: 2px;
}

.feature-box .soft-item .soft-desc {
  color: #ff4f42;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 164px;
}

.feature-box .down-button {
  display: none;
  position: absolute;
  right: 20px;
  top: 30px;
  color: #fff;
  border-radius: 37px;
  background: #2049ee;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  width: 60px;
  height: 30px;
}
</style>
