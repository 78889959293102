<template>
    <div>
        <a-modal title="选择链接" v-model:dialogVisible="dialogVisible" width="60%">

            <a-tabs default-active-key="1">
                <a-tab-pane key="1" tab="商城链接">
                    <div class="link_box">
                        <div>
                            <div class="cont">商城链接</div>
                            <div class="Box">
                                <div class="cont_box" v-for="(item, index) in linkList" :key="index"
                                    @click="selectLink(item, index)" :class="selectIndex === index ? 'link_on' : ''">
                                    <span>{{ item.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </a-tab-pane>



                <a-tab-pane key="2" tab="自定义链接">
                    <a-alert message="温馨提示:请确保自定义域名的前缀为http:// 或 https://" type="info" show-icon />

                    <div class="link_box" style="margin-top: 10px;">
                        <div>
                            <!-- 自定义链接 -->
                            <div class="cont">自定义链接</div>
                            <div class="cont_box" style="margin-top: 20px;">
                                <el-input v-model="link" placeholder="请输入内容"></el-input>
                            </div>
                        </div>
                    </div>
                </a-tab-pane>
            </a-tabs>



            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit()">确 定</el-button>
            </span>
        </a-modal>
    </div>
</template>
<script>

export default {
    data() {
        return {
            dialogVisible: false,
            linkList: [
                { name: "商品列表", link: "/pcate" },
                { name: "我的订单", link: "/porder" },
                { name: "联系客服", link: "/customer" },
                { name: "系统公告", link: "/notice" },
                { name: "调价记录", link: "/priceLog" }
            ],
            selectIndex: undefined,
            link: undefined,
        };
    },

    created() {
    },
    methods: {

        /**
         * 打开弹框
         */
        handleAdd() {
            this.dialogVisible = true;
        },

        /**
         * 选择图片
         */
        selectLink(item, index) {
            this.selectIndex = index;
            this.link = item.link;
        },

        submit() {
            console.log(this.link)
            this.$emit("linkCallback", this.link);
            this.dialogVisible = false;
        }
    }
};
</script>
<style>
.link_box {
    font-size: 14px;
}


.link_box .cont {
    font-weight: 500;
    color: #000;
    font-weight: 700;
}

.link_box .Box {
    margin-top: 19px;
    display: flex;
    flex-wrap: wrap;
}

.link_box .Box .cont_box {
    font-weight: 400;
    color: rgba(0, 0, 0, .85);
    background: #fafafa;
    border-radius: 3px;
    text-align: center;
    padding: 7px 30px;
    margin-right: 10px;
    margin-bottom: 18px;
    cursor: pointer;
}


.link_on {
    background-color: #2d8cf0 !important;
    color: #fff !important;
}
</style>