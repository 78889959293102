<template>
  <div>
    <a-layout>
      <a-layout-header
        style="
          background: linear-gradient(270deg, #009dff, #0550ff);
          padding: 0px !important;
        "
      >
        <a-page-header>
          <template slot="title">
            <a-button type="link" @click="goBack()">
              <div style="color: #ffffff; font-size: 16px">
                <a-icon type="arrow-left" style="margin-right: 10px" />
                返回
              </div>
            </a-button>
          </template>

          <template slot="extra">
            <a-button @click="submitSaveLog" ghost> 保存记录 </a-button>
            <a-button @click="submitSave" ghost> 立即使用 </a-button>
            <a-button @click="submitInitPageStyle" ghost> 初始化页面 </a-button>
          </template>
        </a-page-header>
      </a-layout-header>
      <a-layout>
        <!--组件部分-->

        <a-layout-sider width="300" style="background: #fff">
          <Left
            style="width: 300px"
            ref="left"
            @componentsClick="componentsClick"
            @placeholder="placeholderFun"
            @controlClick="controlClick"
          />
        </a-layout-sider>

        <!-- 内容部分 -->

        <a-layout-content>
          <template>
            <!-- 内容部分 -->
            <div class="diy-wrapper">
              <div
                ref="editorWrapper"
                @dragover.prevent
                @dragenter="dragenter"
                @dragleave="dragleave"
                @dragover="dragovers"
                @drop="drops"
              >
                <div>
                  <div class="browser" style="position: relative">
                    <pagebg
                      :base="item.base"
                      v-for="(item, index) in controlList"
                      :key="index"
                    ></pagebg>

                    <Module
                      :moduleList="moduleList"
                      ref="module"
                      @moduleClick="moduleClick"
                      @moduleMouseovers="moduleMouseovers"
                      @moduleMouseout="moduleMouseout"
                      @deleteMouseout="deleteMouseout"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </a-layout-content>

        <!-- 右侧 -->
        <template>
          <Right ref="Right" @child-right="handleRightClick" />
        </template>

        <template>
          <conAttr ref="KongJian" :attr="conAttrData" />
        </template>
      </a-layout>

      <a-modal title="保存模板记录" :visible="dialogVisible">
        <el-form label-width="80px">
          <el-form-item label="模板名称">
            <el-input
              v-model="templeteName"
              placeholder="请选择模板名称"
            ></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitTemplateLog()"
            >保 存</el-button
          >
        </span>
      </a-modal>
    </a-layout>
  </div>
</template>

<script>
import Left from "@/views/template/pc/Left.vue";
import Module from "@/views/template/pc/Module.vue";
import Right from "@/views/template/pc/Right.vue";
import conAttr from "@/views/template/pc/module/style.vue";
import pagebg from "@/views/template/mobile/module/pagebg.vue";

import moduledata from "@/views/template/pc/module.js";

import "@/views/template/css/diy.css";
import {
  updateTenantTemplate,
  insertHTemplate,
  initPageStyle,
} from "@/api/template";
import { getTenantInfo } from "@/api/index";

export default {
  components: {
    Left,
    Module,
    Right,
    conAttr,
    pagebg,
  },
  data() {
    return {
      id: "", //页面ID
      name: "", //页面名称
      pageIndex: 0, //当前页面
      pageList: [], //页面数据
      activeIndex: "", //移上去模块
      selectIndex: "", //选中模块
      placeholder: false, //显示空白区域
      showOption: false, //显示操作按钮
      optionTop: 0, //操作按钮距离顶部
      control: [], //初始化控件
      moduleList: [], //组件列表
      controlList: [], //控件列表
      showAttr: false, //显示模块属性
      showConAttr: false, //显示控件属性
      curAttrData: [], //当前属性数据
      conAttrData: [], //当前控件属性数据
      saveData: [], //保存数据
      saveConfirm: false,
      showPreview: false, //显示预览
      //模板名称
      templeteName: "",
      dialogVisible: false,
    };
  },

  mounted() {
    this.initPage();
  },

  methods: {
    //初始化页面
    async initPage() {
      var res = await getTenantInfo();

      this.pageList = res.result.templetePc;

      this.$refs.left.pageList = this.pageList;

      this.moduleList = this.pageList.moduleList;
      this.controlList = this.pageList.controlList;
    },

    /**
     * 右侧页面点击
     */
    handleRightClick(json, style) {
      this.moduleList[this.selectIndex].base.s = style;
    },

    //左侧组件点击
    componentsClick(json, style) {
      let that = this;
      let item = JSON.parse(json);
      item.base.s = style;
      this.pageList.moduleList.push(item);
      this.$nextTick(() => {
        if (this.$refs.editorWrapper) {
          that.$util.scrollTop(
            this.$refs.editorWrapper,
            this.$refs.editorWrapper.scrollHeight,
            500
          );
        }
      });
    },

    //空白区域
    placeholderFun(flag) {
      this.placeholder = flag;
      this.$refs.module.placeholder = flag;
    },

    //当拖曳元素进入目标元素的时候触发的事件，此事件作用在目标元素上
    dragenter(event) {
      // 拖动到容器的“放到这里（它是个提示）”盒子，就给盒子加上焦点样式
      if (event.target.classList.contains("placeholder")) {
        event.target.classList.add("on");
      }
    },

    //当拖动的元素或文本选择离开有效的放置目标时将触发该事件。此事件作用在目标元素上（具体请看 html5 DataTransfer对象）
    dragleave(event) {
      // 离开目标容器的“放到这里（它是个提示）”盒子，就给盒子移除焦点样式
      if (event.target.classList.contains("placeholder")) {
        event.target.classList.remove("on");
      }
    },

    // 拖拽元素在目标元素上移动的时候触发的事件
    dragovers(event) {
      event.preventDefault();
    },

    // 拖拽组件区域元素的事件drop 被拖拽的元素在目标元素上同时鼠标放开触发的事件
    drops(event) {
      let style = event.dataTransfer.getData("style"); //获取样式
      let data = JSON.parse(event.dataTransfer.getData("text")); // 获取dragstart事件传递的参数
      data.base.s = parseInt(style);
      this.placeholder = false;
      if (event.target.classList.contains("placeholder")) {
        event.target.classList.remove("on");
      }
      event.dataTransfer.clearData(); // 清除dragstart事件传递的参数
      if (this.pageList.moduleList.length === 0) {
        this.pageList.moduleList.push(data);
      } else if (event.target.classList.contains("placeholder")) {
        let index = event.target.getAttribute("data-index");
        this.pageList.moduleList.splice(index, 0, data);
      }
    },

    //模块获取焦点，显示操作按钮
    moduleMouseovers(item) {
      this.optionTop = item.top - 70;
      this.showOption = true;
      this.activeIndex = item.index;
    },

    // 容器失去焦点，隐藏按钮区域
    moduleMouseout() {
      //this.showOption = false;
    },

    //上移
    up() {
      this.moduleList.splice(
        this.activeIndex - 1,
        0,
        this.moduleList[this.activeIndex]
      );
      this.moduleList.splice(this.activeIndex + 1, 1);
      this.selectIndex = this.activeIndex - 1;
      this.showOption = false;
    },

    //下移
    down() {
      this.moduleList.splice(
        this.activeIndex + 2,
        0,
        this.moduleList[this.activeIndex]
      );
      this.moduleList.splice(this.activeIndex, 1);
      this.showOption = false;
    },

    //复制模块
    copy() {
      let item = JSON.stringify(this.moduleList[this.activeIndex]);
      this.moduleList.push(JSON.parse(item));
    },

    //组件点击
    moduleClick(index) {
      console.log(index);
      this.selectIndex = index;
      this.showConAttr = false;
      this.showAttr = true;
      this.curAttrData = this.moduleList[this.selectIndex];

      this.$refs.Right.childMethod(this.moduleList[this.selectIndex]);
    },

    /**
     * 删除模块
     */
    deleteMouseout() {
      this.$refs.Right.deleteMethod();
    },

    //控件点击显示属性
    controlClick(type) {
      this.showAttr = false;
      this.showConAttr = true;
      this.controlList.forEach((res) => {
        if (res.type == type) {
          this.conAttrData = res;
        }
      }, this);
      this.$refs.KongJian.childKongJian(this.conAttrData);
    },

    //关闭属性
    closeAttr() {
      this.showConAttr = false;
      this.showAttr = false;
    },

    //保存
    async submitSave() {
      let data = {
        templetePc: this.pageList,
      };

      await updateTenantTemplate(data);
      this.$message({
        type: "success",
        message: "修改成功!",
      });
    },

    /*
     * 打开保存历史弹框
     */
    submitSaveLog() {
      this.templeteName = undefined;
      this.dialogVisible = true;
    },

    /**
     * 保存模板历史
     */
    async submitTemplateLog() {
      let data = {
        templeteAddress: JSON.stringify(this.pageList),
        templeteName: this.templeteName,
        templeteType: "Pc",
      };

      await insertHTemplate(data);
      this.$message({
        type: "success",
        message: "保存成功!",
      });
      this.dialogVisible = false;
    },

    /*
     * 初始化页面
     */
    async submitInitPageStyle() {
      // let controlList = JSON.parse(JSON.stringify(moduledata.controlData));

      let pageList = {
        moduleList: [],
        controlList: moduledata.controlData,
      };
      let data = {
        templeteType: "pc",
        templeteContent: JSON.stringify(pageList),
      };

      await initPageStyle(data);
      this.$message({
        type: "success",
        message: "保存成功!",
      });
      this.initPage();
    },

    //控件选择
    controlSelect(item) {
      let json = JSON.stringify(item);
      json = JSON.parse(json);
      let findex = this.controlList.findIndex((res) => res.type == json.type);
      if (findex == -1) {
        this.controlList.push(json);
      } else {
        this.controlList.map(function (item) {
          if (item.type == json.type) {
            item.check = json.check;
          }
        });
      }
    },

    /**
     * 返回上个页面
     */
    goBack() {
      this.$router.back();
    },
  },
};
</script>
<style>
.browser {
  width: 100%;
  margin: 10px auto;
  background-color: #f1f1f1;
  /* border-radius: 8px; */
  overflow: hidden;
}

.browser-header {
  background-color: #eee;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
}

.browser-header .buttons {
  display: flex;
  gap: 8px;
  /* margin-left: auto; */
}

.browser-header .button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #d4d4d4;
}

.browser-header .button.close {
  background-color: #ff5f56;
}

.browser-header .button.minimize {
  background-color: #ffbd2e;
}

.browser-header .button.zoom {
  background-color: #27c93f;
}
</style>
