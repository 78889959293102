<template>
  <div>
    <!-- class="diy-wrapper" -->
    <div style="height: 100%">
      <div class="left" style="background-color: #ffffff">
        <div class="left-control">
          <ul style="border-top: 1px solid #eee">
            <li
              v-for="(item, index) in control"
              :key="index"
              @click.stop="$parent.controlClick(item.type)"
            >
              {{ item.name }}
              <div class="fr">
                <em class="el-icon-edit-outline"></em>
              </div>
            </li>
          </ul>
        </div>

        <a-collapse default-active-key="1" :bordered="false">
          <!-- 基础组件 -->
          <a-collapse-panel key="1" header="基础组件" :style="customStyle">
            <a-row :gutter="16">
              <div
                style="cursor: pointer"
                v-for="(item, index) in list"
                :key="index"
                @click="comClick(item, 0)"
                @dragend="dragend"
                @dragstart="dragstart(item, 0)"
                draggable="true"
              >
                <a-col :span="8">
                  <div
                    style="
                      font-size: 12px;
                      text-align: center;
                      margin-bottom: 30px;
                    "
                  >
                    <a-icon
                      type="appstore"
                      theme="twoTone"
                      style="font-size: 18px"
                    />
                    <p style="margin-top: 5px">{{ item.name }}</p>
                  </div>
                </a-col>
              </div>
            </a-row>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import module from "@/views/template/pc/module.js";

export default {
  name: "Left",
  data() {
    return {
      customStyle:
        "background: #FFFFFF;border-radius: 4px;border: 0;overflow: hidden;",
      id: 1,
      left: [
        { txt: "页面", icon: "el-icon-s-platform" },
        { txt: "模块", icon: "el-icon-menu" },
        { txt: "控件", icon: "el-icon-s-operation" },
      ],
      cur: 0,
      list: [], //模块
      control: [], //控件
      pageList: [], //页面
    };
  },

  mounted() {
    //页面组件
    this.list = module.pageData;
    console.log(this.list);
    this.list.forEach((res, i1) => {
      res.base.sc.forEach((r, i2) => {
        r.p = "s" + (i1 + 1) + "_" + (i2 + 1) + ".png";
      });
    });

    //控件
    this.control = module.controlData;
  },

  methods: {
    dragstart(item, style) {
      event.dataTransfer.setData("style", style);
      event.dataTransfer.setData("text", JSON.stringify(item));
      event.dataTransfer.effectAllowed = "copyMove";
      this.$emit("placeholder", true);
    },

    controlClick(type) {
      this.$emit("controlClick", type);
    },

    dragend() {
      this.$emit("placeholder", false);
    },

    comClick(item, style) {
      console.log(item, style);
      let json = JSON.stringify(item);
      this.$emit("componentsClick", json, style);
    },
    selectcontrol(index) {
      this.$parent.pageList[this.$parent.pageIndex].controlList[
        index
      ].check = true;
    },

    //切换页面
    changePage(index) {
      this.id = this.pageList[index].id;
      this.$parent.changePage(this.id);
    },

    //控件选择
    controlSelect(item) {
      item.check = item.check ? false : true;
      this.$parent.controlSelect(item);
    },
  },
};
</script>
<style scoped>
.div_left_icon {
  margin: 0 auto;
  width: 38px;
  margin-top: 10px;
}

.left .left-control {
  cursor: pointer;
}

.left .left-control ul {
  border-top: 1px solid #eee;
  padding: 0 20px;
}

.left .left-control ul li {
  height: 50px;
  border: 1px solid #eee;
  border-radius: 5px;
  margin-top: 12px;
  display: flex;
  background: #f7f7f7;
  padding: 0 12px;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #666;
}

.left .left-control ul li .fl {
  flex: 1;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
}

.left .left-control ul li .fl.on {
  color: #29c8a1;
}

.left .left-control ul li .fr button {
  background: none;
  border: 0;
}

.left .left-control ul li .fr em {
  margin-left: 8px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
}

.left .left-control ul li .fr em:hover {
  color: #29c8a1;
}

.left .left-control .add-page span {
  margin-right: 12px;
  cursor: pointer;
  height: 45px;
  border: 1px solid #eee;
  margin: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #29c8a1;
  color: #fff;
  border-radius: 40px;
}
</style>
